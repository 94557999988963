<template>
  <div>
    <CarouselPagePackage />
    <SearchBar />
    <PackagesList />
    <RegisterAndLogin />
  </div>
</template>

<script>
export default {
  name: 'SearchPackage',
  components: {
    CarouselPagePackage: () => import('../Layout/home/carousel'),
    SearchBar: () => import('../Layout/home/search_bar'),
    PackagesList: () => import('../Layout/package/result'),
    RegisterAndLogin: () => import('../Layout/home/register_and_login'),
  },
};
</script>
